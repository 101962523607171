import { mapActions as mapPiniaActions, mapState as mapPiniaState } from "pinia";
import { mapActions } from "vuex";
import { logger } from "@/logger";
import { useGebruikerStore } from "@/pinia/gebruiker";

export const missingDataMixin = {
	data: () => ({
		missingProperties: [],
	}),
	async mounted() {
		if (
			["Gebruiker berichten", "Gebruiker nieuw bericht", "Gebruiker bericht"].includes(
				this.$route.name,
			)
		) {
			return;
		}
		await Promise.all([
			this.getBasicInfo(),
			this.getLidInfo(),
			this.getDoelFrequentie(),
			this.getMedical(),
		]);
		this.checkMissingData();
	},
	methods: {
		...mapActions("modal", {
			openModal: "openModal",
		}),
		...mapPiniaActions(useGebruikerStore, [
			"resetState",
			"set",
			"getBasicInfo",
			"getLidInfo",
			"getDoelFrequentie",
			"getMedical",
		]),

		checkMissingData() {
			const missingProperties = [];
			if (!this.lidInfo?.coachingsGroep) {
				// skip if route is medical (bug - lidInfo is not loaded yet)
				missingProperties.push("doelgroep");
			}

			if (!this.doelFrequentie.doelfrequentie) {
				missingProperties.push("doelfrequentie");
			}
			if (this.medical.medischCheck !== 1) {
				missingProperties.push("medischeGegevens");
			}

			if (!this.lidInfo.coachLid) {
				missingProperties.push("coach");
			} else {
				logger.log("coach", this.lidInfo.coachLid);
			}

			if (missingProperties.length === 0) {
				return;
			}

			this.missingProperties = missingProperties;

			this.openModal({
				name: "missing-info",
				data: {
					missingProperties: this.missingProperties,
				},
				callback: async (property) => {
					if (property === "medischeGegevens") {
						this.goToMedisch();
					} else if (property === "coach") {
						this.goToCoach();
					} else if (property === "doelgroep") {
						this.goToDoelGroep();
					} else if (property === "doelfrequentie") {
						this.goToDoelFrequentie();
					}
				},
			});
		},

		async goToDoelFrequentie() {
			await this.navigateTo("Gebruiker persoonlijk gegevens");
			this.showMissingInput("inputDoelFrequentie");
		},

		async goToDoelGroep() {
			await this.navigateTo("Gebruiker persoonlijk gegevens");
			this.showMissingInput("input-coaching-group");
		},

		async goToMedisch() {
			await this.navigateTo("Gebruiker persoonlijk medisch");
		},

		async goToCoach() {
			await this.navigateTo("Gebruiker");
			this.showMissingInput("input-personal-coach");
		},

		showMissingInput(id) {
			const el = document.getElementById(id);
			const defaultBorder = el.style.border;
			el.style.border = "2px solid red";
			const span = this.getErrorSpan("Vul dit veld in");
			el.parentElement?.appendChild(span);

			const listener = () => {
				el.style.border = defaultBorder;
				el.removeEventListener("focus", listener);
				span.remove();
			};

			el.addEventListener("focus", listener);
		},

		getErrorSpan(text) {
			const span = document.createElement("span");
			span.innerText = text;
			span.style.color = "red";
			return span;
		},

		async navigateTo(to) {
			if (this.$route.name !== to) {
				this.$router.push({
					name: to,
					params: {
						id: this.id.toString(),
					},
				});
				// Wait for the route to be updated before validation can start.
				await new Promise((resolve) => setTimeout(resolve, 500));
			}
		},
	},
	computed: {
		...mapPiniaState(useGebruikerStore, {
			id: "id",
			lidInfo: "lidInfo",
			doelFrequentie: "doelFrequentie",
			medical: "medical",
		}),
	},
};
